@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../assets/fonts/WorkSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url(../assets/fonts/WorkSans-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Alex Brush';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  size-adjust: 150%;
  src: url(../assets/fonts/AlexBrush-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../assets/fonts/SpaceGrotesk-Regular.woff2) format('woff2');
}